/* You can add global styles to this file, and also import other style files */
@use '@angular/material/theming';

@use "@ft/table/style/ft_table";

@use "@ft/stock/style/ft_stock";

@use "webdatarocks";
@use "@ft/core/style/utils/variables" as ftVars;
@use "@ft/core/style" as ft ;

@include ft.load-theme();

.validate-icon {
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  align-items: center;

  img {
    opacity: 0.2;
    height: 200px;
    width: 200px;
  }
}

.header-validate-icon {
  right: 50%;
  z-index: 5;
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  //height: inherit;
  align-items: center;

  img {
    opacity: 0.2;
    height: 160px;
    width: 160px;
  }
}

.mat-divider {
  margin: -6px 6px;
  border: 1px solid #eceff1;
}

.mn-amount {
  margin-top: 24px;
  float: right;

  .amounts {
    div:first-child {
      float: left;
      font-weight: 500;
    }

    div:nth-child(2) {
      display: inline-block;
      margin: 0 8px;
    }

    div:last-child {
      float: right;
      text-align: -webkit-right;
      // direction: rtl;
      & > span {
        min-width: 60px;
        text-align: right;
      }
    }

    div > span {
      display: table;
      text-transform: uppercase;
      margin-bottom: 6px;
    }
  }

}

.success-button {
  &.mat-raised-button {
    background-color: ftVars.$ft-success-color;
    color: #fff;

  }
}

.warning {
  color: red;
}

::ng-deep .mat-footer-cell {
  font-weight: 600;
  font-size: 14px;
}

.beneficiary_type_color span {
  background-color: #4fc3f7;
}
